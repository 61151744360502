.search-icon {
    position: absolute;
    z-index: 10;
    font-size: 18px;
    line-height: 38px;
    left: 13px;
    top: 0;
}

.search-close-options{
    right: 10px !important;
    left: auto !important;
    cursor: pointer ;
}
