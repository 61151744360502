.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.job-header {
    width: calc(100% - 15rem);
    @media (max-width: 1500px) {
        width: calc(100% - 10rem);
    }
    @media (max-width: 1200px) {
        width: calc(100% - 2rem);
    }
}

@media (max-width: 700px) {
    .header-item {
        height: 40px;
    }

    .job-hero-section {
        padding-top: 100px;
    }
}

.app-container {
    max-width: 80rem;
}

@media (min-width: 500px) {
    .app-container {
        width: 90%;
    }
}

@media (max-width: 500px) {
    .navbar-landing a {
        padding: 5px 0 !important;
    }
}

.auth-page-content {
    min-height: 90vh;
}

.page-content {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    margin-top: 70px !important;
}

.avatar-xxl{
    width: 10rem;
    height: 10rem;
}

@media print {
    .no-print-section {
        display: none !important;
    }

    /* Hide all buttons when printing */
    button, .btn {
        display: none !important;
    }
}